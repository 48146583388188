export const environments = {
  production: false,
  api: 'https://api-dev.guardadigital.com.br',
  //api: 'https://localhost:7103',
  translate:
    'https://api-dev.guardadigital.com.br/v1/Translate/GetLanguagesTransform?Language=',
  viacepApi: 'https://viacep.com.br/ws/',
  serproApi: 'https://receitaws.com.br/v1',
  COOKIE_EXPIRE_TIME: 99999,
  COOKIE_PATH: '/',
  securityKey: '9Ta1YMPfYAgqlQUzN/qon02rNqT0SG/0',
  efiCredentials: {
    ambiente: 'sandbox',
    client_id: 'Client_Id_b0dfb4ef385bf06b77cbfe7ab7ce6133aa2dbf2d',
    client_secret: 'Client_Secret_24a7259450b9cf795ed0a1e51c695e23153e31b2',
    accountId: '56afc72272e8fcc3f474c04c3e4565b4',
    url: 'https://cobrancas-h.api.efipay.com.br',
  },
  azureAdB2C: {
    tenantId: '29b2d3d8-ed90-4eea-af73-dde82778b556',
    clientId: '67d920d6-f735-4306-bfb9-673f66383e65',
    scopes: ['https://guardadigitalb2c.onmicrosoft.com/api/account'],
    Instance: 'https://login.microsoftonline.com/common/oauth2/v2.0',
    auth: 'https://guardadigitalb2c.b2clogin.com/guardadigitalb2c.onmicrosoft.com/B2C_1_susi',
    edit: 'https://guardadigitalb2c.b2clogin.com/guardadigitalb2c.onmicrosoft.com/b2c_1_edit_profile',
    passReset:
      'https://guardadigitalb2c.b2clogin.com/guardadigitalb2c.onmicrosoft.com/b2c_1_reset',
    knowAuth: ['https://guardadigitalb2c.b2clogin.com'],
    redirectUri: 'https://app-dev.guardadigital.com.br/',
    //redirectUri: 'https://localhost:4200/',
  },
};
